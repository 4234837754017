import axios from 'axios';
const API_BASE_URL = 'https://api.truenorthdrives.com/api';
class ApiService {
  static async createLead(leadData) {
    try {
      const response = await axios.post(`${API_BASE_URL}/lead/create`, leadData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error creating lead:', error);
      throw error.response?.data || new Error('Failed to create lead. Please try again later.');
    }
  }

  static async validateLead(leadData) {
    try {
      const response = await axios.post(`${API_BASE_URL}/lead/validate`, leadData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error validating lead:', error);
      throw error.response?.data || new Error('Lead validation failed. Please verify the information and try again.');
    }
  }

  static async verifyLead(cell_phone, code) {
    try {
      const response = await axios.post(`${API_BASE_URL}/lead/verify-lead`, { cell_phone: cell_phone, code }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error verifying lead:', error);
      throw error.response?.data || new Error('Verification failed. Please try again.');
    }
  }

  static async sendVerificationCode(cell_phone) {
    try {
      const response = await axios.post(`${API_BASE_URL}/verification/send-verification`, {
        cell_phone,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error sending verification code:', error);
      throw error.response?.data || new Error('Failed to send verification code. Please try again later.');
    }
  }

  static async checkVerificationCode(cell_phone, verificationCode) {
    try {
      console.log('Sending verification request with:', { cell_phone, verificationCode });
  
      if (!cell_phone || !verificationCode) {
        throw new Error('Both cell phone and verification code are required');
      }
  
      const response = await axios.post(`${API_BASE_URL}/verification/check-verification`, {
        cell_phone,
        verificationCode,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('Error checking verification code:', error);
      throw error.response?.data || new Error('Verification failed. Please check the code and try again.');
    }
  }
}

export default ApiService;