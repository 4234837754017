import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, Box } from '@mui/material';
import LeadPage from './LeadForm';
import Footer from './Footer'; 

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 500,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ minHeight: 'calc(100vh - 120px)', pt: 8 }}>
        <Box sx={{ mb: 5 }}>
          <Routes>
            <Route path="/" element={<LeadPage />} />
          </Routes>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
