import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  CardActions,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Alert,
} from '@mui/material';
import axios from 'axios';
import ApiService from './services/api';

const CarLoanLeadForm = () => {
  const storedLang = localStorage.getItem('lang') || 'en';

  const [formData, setFormData] = useState({
    lead_source: 'https://cl.truenorthdrives.com',
    ip_address: '',
    campaign_id: 1618,
    product_name: 'car_loan',
    country: 'CA',
    test: 'yes',
    opt_in: 'yes',
    first_name: '',
    last_name: '',
    email: '',
    dob: '',
    city: '',
    state: '',
    postal_code: '',
    cell_phone: '+',
    work_phone: '+',
    address: '',
    own_home: '',
    address_length_months: '',
    rent_mortgage_payment: '',
    property_value: '',
    property_mortgage: '',
    income_type: '',
    has_proof_of_income: '',
    employer: '',
    job_title: '',
    monthly_income: '',
    employed_months: '',
    car_request_type: '',
    car_request_monthly_budget: '',
    credit_score: '',
    credit_auth: '',
    lang: storedLang,
    canadian_status: '',
  });

  const [verificationCode, setVerificationCode] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [userConsent, setUserConsent] = useState(false);
  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const { data } = await axios.get('https://api.ipify.org?format=json');
        setFormData((prevData) => ({ ...prevData, ip_address: data.ip }));
      } catch (error) {
        console.error('Could not retrieve IP address:', error);
      }
    };
    fetchIPAddress();
  }, []);

  const handlePhoneNumberChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, '');

    if (numericValue.length <= 10) {
      setFormData({ ...formData, [name]: `+${numericValue}` });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLangChange = (e) => {
    const selectedLang = e.target.value;
    setFormData({ ...formData, lang: selectedLang });
    localStorage.setItem('lang', selectedLang);
  };

  const sendVerification = async () => {
    if (!formData.cell_phone) {
      setErrorMessage('Please enter your phone number first.');
      return;
    }
  
    try {
      const response = await ApiService.sendVerificationCode(formData.cell_phone);
      setSuccessMessage(response.message || 'Verification code sent successfully.');
    } catch (error) {
      setErrorMessage('Failed to send verification code.');
    }
  };

  const verifyCode = async () => {
    try {
      const response = await ApiService.checkVerificationCode(formData.cell_phone, verificationCode);
      if (response.message === 'Phone number successfully verified.') {
        setIsVerified(true);
        setSuccessMessage(response.message);
      } else {
        setErrorMessage('Invalid verification code.');
      }
    } catch (error) {
      setErrorMessage('Verification failed.');
    }
  };


  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(null);
    setSuccessMessage(null);

    if (!isVerified) {
      setErrorMessage('Please verify your phone number before submitting.');
      return;
    }

    if (!userConsent) {
      setErrorMessage('You must provide consent to submit the form.');
      return;
    }

    const notSpecifiedText = formData.lang === 'fr' ? 'Non spécifié' : 'Not specified';
    const submissionData = {
      ...formData,
      property_value: formData.own_home === 'yes' ? formData.property_value || 0 : 0,
      property_mortgage: formData.own_home === 'yes' ? formData.property_mortgage || 0 : 0,
    };

    try {
      const response = await ApiService.createLead(submissionData);
      setSuccessMessage(response.message || 'Lead successfully submitted.');
      setFormData({
        ...formData,
        first_name: '',
        last_name: '',
        email: '',
        dob: '',
        city: '',
        postal_code: '',
        cell_phone: '+',
        work_phone: '+',
        address: '',
        own_home: '',
        address_length_months: '',
        rent_mortgage_payment: '',
        property_value: '',
        property_mortgage: '',
        income_type: '',
        has_proof_of_income: '',
        employer: '',
        job_title: '',
        monthly_income: '',
        employed_months: '',
        car_request_type: '',
        car_request_monthly_budget: '',
        credit_score: '',
        credit_auth: '',
        canadian_status: '',
      });
    } catch (error) {
      setErrorMessage('Failed to submit the lead.');
    }
  };

  const provinces = [
    { value: 'BC', label: 'British Columbia' },
    { value: 'ON', label: 'Ontario' },
    { value: 'NL', label: 'Newfoundland and Labrador' },
    { value: 'NS', label: 'Nova Scotia' },
    { value: 'PE', label: 'Prince Edward Island' },
    { value: 'NB', label: 'New Brunswick' },
    { value: 'QC', label: 'Quebec' },
    { value: 'MB', label: 'Manitoba' },
    { value: 'SK', label: 'Saskatchewan' },
    { value: 'AB', label: 'Alberta' },
    { value: 'NT', label: 'Northwest Territories' },
    { value: 'NU', label: 'Nunavut' },
    { value: 'YT', label: 'Yukon' },
  ];

  const today = new Date();
  const maxDate = today.toISOString().split('T')[0];
  const minYear = 2006;
  const minDate = new Date(minYear, today.getMonth(), today.getDate()).toISOString().split('T')[0];

  const fields = [
    { label: 'First Name', name: 'first_name', type: 'text', required: true },
    { label: 'Last Name', name: 'last_name', type: 'text', required: true },
    { label: 'Email', name: 'email', type: 'text', required: true },
    { label: 'City', name: 'city', type: 'text', required: true },
    { label: 'Postal Code', name: 'postal_code', type: 'text', required: true },
    { label: 'Cell Phone', name: 'cell_phone', type: 'tel', required: true, onChange: handlePhoneNumberChange },
    { label: 'Work Phone', name: 'work_phone', type: 'tel', onChange: handlePhoneNumberChange, required: true },
    { label: 'Address', name: 'address', type: 'text', required: true },
    { label: 'Address Length (Months)', name: 'address_length_months', type: 'number', required: true },
    { label: 'Rent/Mortgage Payment', name: 'rent_mortgage_payment', type: 'number', required: true },
    { label: 'Employer', name: 'employer', type: 'text', required: true },
    { label: 'Job Title', name: 'job_title', type: 'text', required: true },
    { label: 'Property Value', name: 'property_value', type: 'number', required: true },
    { label: 'Property Mortgage', name: 'property_mortgage', type: 'number', required: true },
    { label: 'Car Request Monthly Budget', name: 'car_request_monthly_budget', type: 'number', required: true },
  ];
  
  const selectFields = [
    { label: 'State', name: 'state', options: provinces, required: true },
    { label: 'Own Home', name: 'own_home', options: [{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }], required: true },
    { label: 'Income Type', name: 'income_type', options: [
        { value: 'full_time', label: 'Full Time' },
        { value: 'part_time', label: 'Part Time' },
        { value: 'self_employed', label: 'Self Employed' },
        { value: 'gig_worker', label: 'Gig Worker' },
        { value: 'student', label: 'Student' },
        { value: 'homemaker', label: 'Homemaker' },
        { value: 'retired', label: 'Retired' },
        { value: 'disability', label: 'Disability' },
        { value: 'social_security', label: 'Social Security' },
        { value: 'unemployed', label: 'Unemployed' },
        { value: 'other', label: 'Other' },
        { value: 'temp_contract', label: 'Temporary/Contract' },
      ], required: true },
    { label: 'Proof of Income', name: 'has_proof_of_income', options: [{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }], required: true },
    { label: 'Credit Authorization', name: 'credit_auth', options: [{ value: '1', label: 'Yes' }, { value: '0', label: 'No' }], required: true },
    { label: 'Credit Score', name: 'credit_score', options: [
        { value: 'low', label: 'Low' },
        { value: 'fair', label: 'Fair' },
        { value: 'good', label: 'Good' },
        { value: 'unknown', label: 'Unknown' },
      ], required: true },
    { label: 'Canadian Status', name: 'canadian_status', options: [
        { value: 'pr', label: 'Permanent Resident' },
        { value: 'citizen', label: 'Citizen' },
        { value: 'work_permit', label: 'Work Permit' },
        { value: 'student_visa', label: 'Student Visa' },
        { value: 'other', label: 'Other' },
      ], required: true },
    { label: 'Monthly Income', name: 'monthly_income', options: [
        { value: '1500', label: '1500 or less' },
        { value: '2000', label: '1501 - 2000' },
        { value: '3000', label: '2001 - 3000' },
        { value: '4000', label: '3001 - 4000' },
        { value: '5000', label: '4001 - 5000' },
        { value: '5500', label: '5000 or more' },
      ], required: true },
    { label: 'Employed Months', name: 'employed_months', options: [
        { value: '2', label: 'Under 3 months' },
        { value: '6', label: '3 to 6 months' },
        { value: '12', label: '6 to 12 months' },
        { value: '24', label: '12 or more months' },
      ], required: true },
    { label: 'Car Request Type', name: 'car_request_type', options: [
        { value: 'sedan', label: 'Sedan' },
        { value: 'suv', label: 'SUV' },
        { value: 'van', label: 'Van' },
        { value: 'truck', label: 'Truck' },
        { value: 'luxury', label: 'Luxury' },
        { value: 'unknown', label: 'Unknown' },
      ], required: true },
  ];

  const localization = {
    en: {
      first_name: 'First Name',
      last_name: 'Last Name',
      email: 'Email',
      city: 'City',
      state: 'State',
      postal_code: 'Postal Code',
      cell_phone: 'Cell Phone',
      work_phone: 'Work Phone',
      address: 'Address',
      own_home: 'Own Home',
      address_length_months: 'Address Length (Months)',
      rent_mortgage_payment: 'Rent/Mortgage Payment',
      property_value: 'Property Value',
      property_mortgage: 'Property Mortgage',
      income_type: 'Income Type',
      has_proof_of_income: 'Proof of Income',
      employer: 'Employer',
      job_title: 'Job Title',
      monthly_income: 'Monthly Income',
      employed_months: 'Employed Months',
      car_request_type: 'Car Request Type',
      car_request_monthly_budget: 'Car Request Monthly Budget',
      credit_score: 'Credit Score',
      credit_auth: 'Credit Authorization',
      canadian_status: 'Canadian Status',
      dob: 'Date of Birth',
      language: 'Language',
      verification_code: 'Verification Code',
      send_verification_code: 'Send Verification Code',
      verify_phone_number: 'Verify Phone Number',
      submit_application: 'Submit Application',
      consent_text: "In order to maximize my chance of finding a service provider, I authorize the trusted financial services provider I've been matched with to obtain credit reports from credit reporting agencies to assess my credit history. My consent is valid until I notify the matched provider of its withdrawal.",
    },
    fr: {
      first_name: 'Prénom',
      last_name: 'Nom de Famille',
      email: 'Email',
      city: 'Ville',
      state: 'État',
      postal_code: 'Code Postal',
      cell_phone: 'Téléphone Portable',
      work_phone: 'Téléphone de Travail',
      address: 'Adresse',
      own_home: 'Propriétaire',
      address_length_months: 'Durée de Résidence (Mois)',
      rent_mortgage_payment: 'Loyer/Versement Hypothécaire',
      property_value: 'Valeur de la Propriété',
      property_mortgage: 'Hypothèque de la Propriété',
      income_type: 'Type de Revenu',
      has_proof_of_income: 'Justificatif de Revenu',
      employer: 'Employeur',
      job_title: 'Titre du Poste',
      monthly_income: 'Revenu Mensuel',
      employed_months: 'Mois d\'Emploi',
      car_request_type: 'Type de Voiture Demandée',
      car_request_monthly_budget: 'Budget Mensuel pour Voiture',
      credit_score: 'Cote de Crédit',
      credit_auth: 'Autorisation de Crédit',
      canadian_status: 'Statut Canadien',
      dob: 'Date de Naissance',
      language: 'Langue',
      verification_code: 'Code de Vérification',
      send_verification_code: 'Envoyer le Code de Vérification',
      verify_phone_number: 'Vérifier le Numéro de Téléphone',
      submit_application: 'Soumettre la Demande',
      consent_text: "Afin de maximiser mes chances de trouver un fournisseur de services, j'autorise le fournisseur de services financiers de confiance avec lequel j'ai été mis en relation à obtenir des rapports de crédit des agences d'évaluation pour évaluer mon historique de crédit. Mon consentement est valable jusqu'à ce que j'informe le fournisseur associé de son retrait.",
    }
  };
  
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 6, display: 'flex', justifyContent: 'center' }}>
        <Card sx={{ maxWidth: 800, width: '100%' }}>
          <CardContent>
            <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
              Car Loan Application Form
            </Typography>

            {successMessage && (
            <Alert severity="success" onClose={() => setSuccessMessage(null)} sx={{ mb: 3 }}>
              {successMessage}
            </Alert>
            )}
            {errorMessage && (
              <Alert severity="error" onClose={() => setErrorMessage(null)} sx={{ mb: 3 }}>
                {errorMessage}
              </Alert>
            )}

            <TextField
              select
              fullWidth
              label="Language"
              value={formData.lang}
              onChange={handleLangChange}
              sx={{ mb: 2 }}
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="fr">French</MenuItem>
            </TextField>
            <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label={localization[formData.lang].dob}
              name="dob"
              type="date"
              value={formData.dob}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: minDate,
                max: maxDate,
              }}
            />
            </Grid>
              {fields.map((field) => (
                <Grid item xs={12} md={6} key={field.name}>
                  <TextField
              fullWidth
              label={localization[formData.lang][field.name]}
              name={field.name}
              type={field.type || 'text'}
              value={formData[field.name]}
              onChange={field.onChange || handleInputChange}
              required={field.required}
              disabled={field.disabled}
              inputProps={field.inputProps}
                />
            </Grid>
            ))}

              {selectFields.map((field) => (
            <Grid item xs={12} md={6} key={field.name}>
          <TextField
              select
              fullWidth
              label={localization[formData.lang][field.name]}
              name={field.name}
              value={formData[field.name]}
              onChange={handleInputChange}
              required={field.required}
            >
      {field.options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
        </Grid>
      ))}
     <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={localization[formData.lang].verification_code}
                    value={verificationCode}
                    onChange={(e) => {
                      const value = e.target.value.slice(0, 6);
                      setVerificationCode(value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={sendVerification}>
                  {localization[formData.lang].send_verification_code}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={verifyCode}>
                  {localization[formData.lang].verify_phone_number}
                  </Button>
                </Grid>
               
                {}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={userConsent}
                        onChange={() => setUserConsent(!userConsent)}
                      />
                    }
                    label={localization[formData.lang].consent_text}
                  />
                </Grid>
              </Grid>
            </form>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!userConsent}
            >
              {localization[formData.lang].submit_application}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Container>
  );
};

export default CarLoanLeadForm;