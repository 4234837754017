import React from 'react';
import { Box, Typography, Link, Stack } from '@mui/material';

const Footer = () => {
  return (
    <Box component="footer" sx={{ bgcolor: 'primary.main', color: 'white', py: 2, px: 3, mt: 'auto' }}>
      <Stack spacing={1} alignItems="center">
        <Typography variant="body2">
          Address: 500 4th Avenue SW, Suite 2500, Calgary, Alberta, Canada T2P 2V6
        </Typography>
        <Typography variant="body2">
          Phone: <Link href="tel:+15875749887" color="inherit">+1 587-574-9887</Link>
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="center">
          <Link href="https://www.facebook.com" target="_blank" color="inherit">Facebook</Link>
          <Link href="https://www.instagram.com" target="_blank" color="inherit">Instagram</Link>
        </Stack>
        <Typography variant="body2" align="center">
          &copy; 2024 TRUE NORTH DRIVES
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="center">
          <Link href="/" color="inherit">Home</Link>
          <Link href="/privacy-policy" color="inherit">Privacy Policy</Link>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Footer;
